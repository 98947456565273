/* .addnews label{
 font-weight: bold;
}
.addnews input:focus{
    box-shadow: none;
}
.addnews select:focus{
    box-shadow: none;
} */
.form-group label{
    font-weight: bold;
   }
   .form-group input:focus{
       box-shadow: none;
   }
   .form-group select:focus{
       box-shadow: none;
   }
.card-header{
    color: #7386D5;
    font-size: 1.5rem !important;
    font-weight: bold!important;
}
.card{
    width: 100%;
}
/* .form-group input{
    width: 350px;
} */
/* .addnews-container form{
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
} */
.add-img input{
    width: 270px !important;
}
.ck-editor-container{
    width: 100%;
    max-width: 100% !important;
}
.req-feild{
    color: red;
}
.news-description{
    max-width: 280px !important;
}
.news-items{
    min-width: fit-content !important;
    max-width: 150px !important;
}
.filter-container{
    padding: 20px;
    display: flex;
    gap: 20px;
}
.filter-container .form-group select{
    max-width: max-content !important;
    font-weight: 700;
}
.ck-content{
    max-height: 500px !important;
    /* height: 400px !important; */
    border: 1px solid cornflowerblue !important;
}
.news-img-container{
    display: contents;
}
.news-banner-image-preview {
    margin-top: 1.5rem;
    width: 340px;
    height: 340px;
    overflow: hidden;
    border: 1px solid #ccc;
}

.news-banner-image-preview img {
    width: 100%; /* Make the image fill the container width */
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Remove default inline spacing */
  }
/* .table tbody th{
    min-width: fit-content;
} */

.import-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6.5rem 2.5rem;
}