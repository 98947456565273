/*
    DEMO STYLE
*/

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
body {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #393838;
}
p strong{
  font-weight: 900;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  position: sticky;
  top: 0;
  min-width: 275px !important;
  max-width: 275px !important;
  background: #7386d5;
  color: #fff;
  transition: all 0.3s;
  height: 100vh;
  /* overflow-x: scroll; */
  overflow-y: scroll;
}
#sidebar::-webkit-scrollbar {
  width: 10px; /* Adjust the width of the scrollbar */
}

#sidebar::-webkit-scrollbar-thumb {
  background-color:rgb(168, 168, 168); /* Customize the color of the scrollbar thumb */
  border-radius: 6px; /* Adjust the border-radius to make the scrollbar thumb rounder */
}

#sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #444444; /* Customize the color of the scrollbar thumb on hover */
}

#sidebar::-webkit-scrollbar-track {
  background-color: #7386d5 !important; /* Customize the color of the scrollbar track */
}


#sidebar.active {
  margin-left: -275px !important;
}

#sidebar .sidebar-header {
  /* padding: 25px; */
  background: #6d7fcc;
  font-weight: bolder;
}
.sidebar-header h3 {
  background: none;
  font-family: cursive;
  font-weight: bolder!important;
  /* color: #7aff79; */
  /* box-shadow: rgba(-9.7, 8.1, -1.2, 0.35) -7px 17px 4px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding: 25px;
  font-size: 1.8rem;
}
#sidebar ul.components {
  /* padding: 20px 0; */
  border-bottom: 1px solid #47748b;
}

/* #sidebar ul .dashboard {
    color: #fff;
    padding: 10px;
} */
.dashboard {
  padding: 1rem 0 !important;
  font-weight: bold;
}
/* .dashboard:hover {
  color: #7386D5;
  background: #fff;
} */
#sidebar ul li a {
  padding: 1rem 10px;
  font-size: 17px;
  display: block;
  font-weight: 500;
}
#sidebar ul li .dropdown-toggle::after {
  right: 0px;
}

#sidebar ul li a:hover {
  color: #7386d5;
  background: #fff;
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  /* #sidebar {
    margin-left: -275px;
  } */
  #sidebar {
    margin-left: 0;
    min-width: none !important;
    max-width: none !important;
    height: auto !important;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}
/* dashboard.css */
#content {
  width: 100%;
  padding: 15px;
  transition: all 0.3s;
}

/* Toggle Button */
#sidebarCollapse {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

/* Navbar Styles */
.navbar {
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.navbar .navbar-toggler {
  background-color: #37474f;
  border: none;
  color: white;
  cursor: pointer;
}

/* Miscellaneous */
.line {
  height: 1px;
  background-color: #ccc;
  margin: 20px 0;
}

.list-unstyled {
  padding: 0;
  list-style: none;
}

.list-unstyled a {
  color: white;
  text-decoration: none;
}

.list-unstyled a:hover {
  color: #fff;
}

/* Active link */
.list-unstyled .active > a {
  background-color: #263238;
  color: #fff;
}

/* Dropdown Styles */
.collapse.show {
  display: block;
}

.list-unstyled .dropdown-toggle::after {
  float: right;
  margin-left: auto;
  margin-right: 15px;
}

/* CTAs Styles */
.CTAs {
  padding: 20px;
}

.CTAs a {
  color: #007bff;
  text-decoration: none;
  transition: all 0.3s;
}

.CTAs a:hover {
  color: #0056b3;
  text-decoration: none;
}

/* Additional Styling for Overlay */
#overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.auth-btns button {
  background: white;
  font-weight: 600;
}
.auth-btns li a{
  /* padding: 0; */
  background: rgb(46, 46, 248);
  font-weight: bold !important;
  color: white;
  padding: .8rem !important;
}
.auth-btns li{
  /* padding: .5rem; */
}
.nav-searchBar {
  background: white;
  display: flex;
  align-items: center;
  padding: 5px;
}
.nav-searchBar input {
  outline: none;
  border: none;
  height: 1.5rem;
}
.profile-dropdown li {
  width: 100%;
  /* text-align: right; */
  padding: 1rem 0;
  list-style: none;
  line-height: 1rem;
  font-size: 1rem;
  font-weight: 600;
}
.profile-dropdown {
  padding-left: 10px;
  position: absolute;
  right: 40px;
  width: 20rem;
  /* width: 30%; */
  /* max-width: 30%; */
  background: lightseagreen;
  top: 90px;
  border-radius: 5px;
  color: #fff;
  z-index: 10;
}
.logout-button {
  cursor: default;
}
.logout-button:hover {
  cursor: pointer;
}
