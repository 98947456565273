.postPageAdContainer {
    /* border: 1px solid black; */
    margin: 10px 0;
  }
  .postPageAdContainer img {
    width: 100%;
  }
  .news-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    /* padding: 1rem 0; */
    /* border-bottom: 2px dotted #bdbdbd; */
    border-bottom: 1px dotted #122241;
    /* margin-bottom: 14px; */
  }
  .news-heading h2 {
    font-family: "Satoshi";
    font-size: 18px !important;
    font-style: normal;
    text-transform: uppercase !important;
    font-weight: 700;
    margin-bottom: 4px;
    /* line-height: 0rem; */
    /* display: block; */
    min-width: 30%;
    /* letter-spacing: 1px; */
  }
  .news-heading hr {
    /* width: 75%; */
    height: 1px;
    /* max-width: 490px; */
    width: 65%;
    /* height: 1px; */
    background: rgba(0, 0, 0, 1);
  }
  .post-related-news {
    /* background: #fafafa; */
    padding: 5px;
    border-radius: 5px;
  }
  .post-related-news .opinionLatestRight {
    width: 49%;
  }
  .news-content {
    /* background: #f5f5f5;
    padding: 16px; */
    border-radius: 8px;
  }
  .news-content ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px;
  }
  .news-content ul li {
    width: 48%;
    list-style: none;
    font-family: "Switzer-variable";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
    /* border-bottom: solid 1px #cacaca; */
    border-bottom: 1px dotted #122241;
    padding: 10px 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }
  .news-content ul li h3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px !important;
    line-height: 22px;
    -webkit-line-clamp: 3;
    color: #000;
    margin: 0px;
  }
  
  .postPage-main div {
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    background: #f8f8f8;
    /* padding: 5px 10px; */
  }
  .breadcrumb-item a {
    text-transform: capitalize;
    font-size: 12px;
    color: black;
    opacity: 100%;
    font-weight: 500;
  }
  .post-page-main-container {
    margin-top: 10px !important;
  }
  
  .postPage-main blockquote {
    padding-left: 15px;
    border-left: 7px solid #ccc7c7;
    border-radius: 2px;
  }
  .postPage-main .text-huge {
    font-size: xxx-large;
    font-weight: 800;
    line-height: 1;
  }
  .postPage-main h4 .image-style-align-left {
    vertical-align: baseline;
  }
  .img-main {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #000;
    /* margin: 10px 0; */
    margin: 0px 0px 0px 0px;
    object-fit: cover;
    object-position: center 25%;
    /* height: 282px; */
    height: 380px;
  }
  @media (max-width: 600px) {
    .img-main {
      height: 210px;
    }
  }
  .postPage-main .text-small {
    font-size: 13px;
  }
  .postPage-main .big-img-container {
    display: block !important;
  }
  #post-content-ck-edit figure div {
    /* background: green !important; */
  }
  .media div {
    /* background: white !important; */
  }
  .post-img-caption {
    opacity: 70%;
    font-size: 12px;
    font-style: oblique;
    margin-bottom: 10px;
  }
  .image img {
    max-width: 95% !important;
    height: 100%;
  }
  .postPageByLine .author-details .author-name span {
    font-size: 14px !important;
  }
  .postPageByLine .author-details {
    font-size: 12px !important;
  }
  .postPageByLine .news-time {
    font-size: 12px !important;
  }
  #post-content-ck-edit {
    font-family: "Switzer-Variable";
    line-height: 28px;
  }
  
  #post-content-ck-edit img {
    max-width: 98%;
    height: auto;
  }
  
  #post-content-ck-edit .table table {
    border-collapse: collapse;
    width: 100%;
  }
  #post-content-ck-edit .table table td {
    border: 1px solid black;
    padding: 6px;
    text-align: left;
    font-size: 14px;
    line-height: 22px;
  }
  #post-content-ck-edit .table table td p {
    margin: 0px;
    font-size: 14px !important;
  }
  #post-content-ck-edit .image-style-align-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  #post-content-ck-edit .image-style-align-right {
    /* display: flex; */
    /* justify-content: right; */
    float: inline-end;
    padding-left: 10px;
  }
  #post-content-ck-edit .image-style-align-left {
    /* display: flex; */
    /* justify-content: right; */
    float: inline-start;
    padding-right: 10px;
  }
  #post-content-ck-edit .text-big {
    font-size: xx-large;
    font-weight: 500;
    line-height: 1;
  }
  #post-content-ck-edit figcaption {
    opacity: 70%;
    font-size: 12px;
    font-style: oblique;
    margin-bottom: 10px;
  }
  #post-content-ck-edit .media,
  iframe {
    width: 100%;
  }
  /* #post-content-ck-edit .media{
    width: 100%;
  } */
  /* .postPageByLine .author-details .author-details .news-time {} */
  .postPageByLine .author-details {
    display: flex;
    flex-direction: column;
    line-height: 18px;
    margin-bottom: 0;
  }
  .postPageByLine {
    width: 100%;
    display: flex;
    /* gap: 183px; */
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
  }
  .social-media-share-btns {
    display: flex;
    gap: 10px;
  }
  .social-media-share-btns,
  svg,
  button {
    /* color: #878787!important; */
  }
  .social-media-share-btns svg {
    width: 20px;
    /* color: #878787; */
    color: #6a6a6a !important;
  }
  @media (max-width: 405px) {
    .social-media-share-btns svg {
      width: 20px;
      color: #878787;
    }
    .social-media-share-btns {
      /* display: flex; */
      gap: 10px;
      /* align-items: baseline; */
    }
    .postPageByLine {
      align-items: end;
    }
    .postPageByLine .news-time {
      font-size: 12px !important;
      color: #a1a1a1;
    }
    .img-main {
      height: auto;
    }
  }
  .social-media-share-btns button {
    width: 24px;
    color: #878787;
  }
  /* .postPage-main .author-details{ */
  
  .postPage-main .iframely-embed {
    margin: auto;
  }
  .postPage-main .iframely-responsive {
    height: 560px !important;
  }
  .postPage-main .iframely-responsive iframe {
    height: 100% !important;
  }
  @media (max-width: 1220px) {
    .post-page-main-container {
      margin-top: 60px !important;
      padding: 0 !important;
    }
  }

  


  @import url("https://fonts.googleapis.com/css2?family=Alumni+Sans:wght@400;500;600;800&family=Roboto:wght@300&display=swap");
.opinion-main {
  width: 75% !important;
  margin: auto;
  margin-top: 130px;
  height: 100%;
}
.opinion-container {
  display: flex;
}
.opinion-left {
  width: 65%;
}
.opinion-right {
  width: 35%;
}
.opinion-container hr {
  width: 3px;
  height: 100%;
  background: rgb(155, 149, 149);
  margin: 5px;
}
.opinionPagenation {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.pagination a {
  color: black;
  font-size: 12px;
  font-weight: 500;
}
.pagination a:active {
  box-shadow: none;
}
.pagination a:focus {
  box-shadow: none;
}
.postHeading {
  color: #000;
  leading-trim: both;
  text-edge: cap;

  /* H1-switzer-inner-40-bold */
  font-family: "Switzer-variable";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 125% */
}
.inner-category-btn {
  display: inline-flex;
  /* height: 40px; */
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #bebebe;
  background: #fff;
  margin: 5px 10px 10px 0px;

  leading-trim: both;
  text-transform: capitalize;
  text-edge: cap;
  font-family: "Alumni Sans" !important;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 127.273% */
}
.inner-category-btn:focus {
  background: var(--Primary, #e82327) !important;
  color: #fff;
}
.opinionTop-main-flex-world {
  /* padding: 10px;
  border: 1px solid gray; */
  /* border-radius: 10px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}
.world-videos-section-main {
  width: 990px;
  height: 731px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #090909;
}


@media(max-width:500px){
   .postHeading{
    font-size: 24px!important;
    line-height: 28px;
   }
   .post-heading-container p{
    font-size: 14px;
   }
   #post-content-ck-edit{
    line-height: 24px!important;
   }
}