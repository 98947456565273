/* login.css */

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 75vw;
    margin: 0;
  }
  
  .login-container .card {
    width: 90%;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .login-container .card .card-header {
    background-color: #007bff;
    color: #fff;
    padding: 15px;
    text-align: center;
    font-size: 18px;
  }
  
  .login-container .card .card-body {
    padding: 20px;
  }
  
  .login-container .card .card-body .form-group {
    margin-bottom: 20px;
  }
  
  .login-container .card .card-body .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .login-container .card .card-body .form-group .form-control {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .login-container .card .card-body button {
    width: 100%;
    padding: 10px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-container .card .card-body button:hover {
    background-color: #c82333;
  }
  
  .login-container .line {
    height: 3px;
    width: 30%;
    background-color: #007bff;
    margin: 20px auto;
  }

  .image-preview {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 50%;
  }

  .image-preview img {
    width: 100%; /* Make the image fill the container width */
    height: auto; /* Maintain the aspect ratio of the image */
    display: block; /* Remove default inline spacing */
  }
  
  /* Optional: Add some responsive styles */
  
  @media (max-width: 600px) {
    .login-container .card {
      width: 80%;
    }
  }
  