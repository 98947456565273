/* .addnews label{
 font-weight: bold;
}
.addnews input:focus{
    box-shadow: none;
}
.addnews select:focus{
    box-shadow: none;
} */
.form-group label {
    font-weight: bold;
  }
  .form-group input:focus {
    box-shadow: none;
  }
  .form-group select:focus {
    box-shadow: none;
  }
  .card-header {
    color: #7386d5;
    font-size: 1.5rem !important;
    font-weight: bold !important;
  }
  .card {
    width: 100%;
  }
  /* .form-group input {
    width: 350px;
  }
  .addnews-container form {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  } */
  .add-img input {
    width: 270px !important;
  }
  .ck-editor-container {
    width: 100%;
    max-width: 100% !important;
  }
  .req-feild {
    color: red;
  }
  .news-description {
    max-width: 280px !important;
  }
  .news-items {
    min-width: fit-content !important;
    max-width: 150px !important;
  }
  .filter-container {
    padding: 20px;
    display: flex;
    gap: 20px;
  }
  .filter-container .form-group select {
    max-width: max-content !important;
    font-weight: 700;
  }
  .ck-content {
    max-height: 500px !important;
    /* height: 400px !important; */
    border: 1px solid cornflowerblue !important;
  }
  .news-img-container {
    display: contents;
  }
  /* .table tbody th{
      min-width: fit-content;
  } */
  .draft-btn button {
    float: right;
    /* margin-right: 10%; */
    outline: none;
    border: none;
    padding: 7px;
    font-weight: 600;
    background: cornflowerblue;
  }
  
  .iframely-embed {
    height: 560px;
    max-height: 600px;
  }
  .iframely-embed .iframely-responsive {
    height: 560px;
    max-height: 600px;
  }
  .iframely-embed iframe {
    height: 100%;
  }
  