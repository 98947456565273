.manage-category{
    width: 90%;
    margin: auto;
}
.thead-dark tr{
    /* border-radius: 15px; */
}

.ck-editor-container{
  width: 100%;
  max-width: 100% !important;
}

/* Add this to your CSS or stylesheets */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-btn {
    background-color: #f7f7f7;
    color: #333;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 25px;
    margin: 0 4px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .pagination-btn:hover {
    background-color: #ddd;
  }
  
  .current-page {
    font-size: 18px;
    font-weight: bold;
    margin: 0 10px;
    color: #555;
  }
  .action-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .action-button:focus {
    outline: none;
  }
  